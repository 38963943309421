import { useI18nContext } from "i18n/i18n-react"
import React from "react"
import { AxiosResponse } from "axios";
import { FilePreview } from "@sis-lab/web-ui-components";
import { downloadBlobFile } from "utils";
import useFetchFile from "./components/useFetchFile";

interface Props {
  fetchFile: () => Promise<AxiosResponse<ArrayBuffer>>
  documentId: string
}


export default function CorpoFilePreview({ fetchFile, documentId }: Props) {
  const [filePreview, fileDownload, loading] = useFetchFile(fetchFile, documentId);
  const { LL } = useI18nContext()
  
  return (
    <FilePreview
      className=''
      downloadDocument={() => downloadBlobFile(fileDownload?.blob!, fileDownload?.fileName!)}
      title={LL.documentInfoCard.preview()}
      notAvailableText={LL.documentInfoCard.preview404()}
      file={filePreview?.blob || fileDownload?.blob}
      fileName={filePreview?.fileName || fileDownload?.fileName}
      loading={loading}
    />
  )
}
