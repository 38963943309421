import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  Label,
  Modal,
  TextArea,
  useBreakpoints,
  useForm
} from "@sis-lab/web-ui-components";
import React, { useState } from "react";
import { useI18nContext } from "i18n/i18n-react";
import { useSelector } from "react-redux";

import { ApplicationState } from "modules";
import { GeneratePrmParams, PRM } from "apis/prmApi/prm/types";
import { PrmDocument } from "apis/xml/types";
import { EMAIL_REG_EXP } from "utils/consts";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import moment from "moment";
import { removeEmptyStringProperties } from "utils/requests/removeEmptyStringProperties";
import prmApi from "apis/prmApi";
import DocumentTypeSelector from "components/DocumentTypeSelector/DocumentTypeSelector";
import { errorParser } from "utils/requests/errorParser";
import PaymentUpload from "./components/PaymentUpload";
import styles from './NewPaymentModal.module.scss'

interface Props {
  isOpen: boolean
  addNewPayment: (newPrm: PRM) => void
  onCancel: () => void
}


export default function NewPaymentModal({ isOpen, addNewPayment, onCancel }: Props) {
  const sub = useSelector((state: ApplicationState) => state.authentication.user?.sub) // should be rm

  const [loading, setLoading] = useState(false)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [prmDocument, setPrmDocument] = useState<PrmDocument>()
  const [skipGenerateActions, setSkipGenerateActions] = useState(false)
  const [convertPdfToXml, setConvertPdfToXml] = useState(false)
  const { mobile } = useBreakpoints() || {}
  const { LL } = useI18nContext()
  const currentMoment = moment()
  const form = useForm()

  const onClose = () => {
    form.reset({
      amount: '',
      description: '',
      title: '',
      payerEmail: '',
      payerName: '',
      payerId: '',
      documentName: '',
      documentType: '',
      documentContentType: '',
      reqdExctnDt: '',
      xpryDt: '',
    })
    setSkipGenerateActions(false)
    form.clearErrors()
    setPrmDocument(undefined)
    onCancel()
  }


  const onSubmit = async () => {
    form.clearErrors()
    const errors: { field: string, message: string }[] = []
    const data = form.getValues()

    if (!data.title) errors.push({ field: 'title', message: 'Please provide payment title' })
    if (!data.description) errors.push({ field: 'description', message: 'Please provide payment description' })
    if (!data.amount) errors.push({ field: 'amount', message: 'Provide a valid amount' })
    if (data.payerEmail && !data.payerEmail.match(EMAIL_REG_EXP)) {
      errors.push({ field: 'payer', message: LL.errors.common.invalidEmail() });
    }

    if (errors.length > 0) {
      errors.forEach(({ field, message }) => form.setError(field, { message }))
      return
    }

    const newPayment = {
      subId: sub || '',
      orderId: data.title,
      description: data.description,
      currency: "EUR",
      amount: data.amount,
    } as GeneratePrmParams

    if (data.payerEmail || data.payerId) {
      newPayment.payer = {
        name: data.payerName,
        email: data.payerEmail,
        id: data.payerId,
      }
    }

    if (data.reqdExctnDt) newPayment.reqdExctnDt = data.reqdExctnDt.toISOString();
    if (data.xpryDt) newPayment.xpryDt = data.xpryDt.toISOString();
    if (skipGenerateActions) newPayment.skipPostGenerateActions = true;

    if (prmDocument) {
      newPayment.document = {
        name: form.getValues('documentName') || prmDocument.name,
        content: prmDocument.content,
        contentType: form.getValues('documentContentType') || prmDocument.contentType,
        type: form.getValues('documentType')
      };
    }

    setLoading(true);

    try {
      const cleanPayment = removeEmptyStringProperties<GeneratePrmParams>(newPayment)
      const { data: newPrm } = await prmApi.generatePrm(cleanPayment);
      addNewPayment(newPrm)
      setLoading(false)
      onClose();
    } catch (e) {
      setLoading(false)
      // eslint-disable-next-line no-alert
      errorParser(e, err => alert(err.response?.data))
    }
  }

  return (
    <Modal
      title={<><Icon name='add' /><Label className={styles.title}>{LL.newPaymentModal.title()}</Label></>}
      className={styles.modal}
      onCancel={onClose}
      open={isOpen}
      footer={
        <div className={styles.footer}>
          <Checkbox
            className={styles.checkbox}
            checked={showAdvanced}
            onChange={() => setShowAdvanced(!showAdvanced)}
            children={LL.newPaymentModal.showAdvanced()}
          />
          <Checkbox
            className={styles.checkbox}
            children='Convert PDF to XML'
            value={convertPdfToXml}
            onChange={() => setConvertPdfToXml(!convertPdfToXml)}
          />
          <Button
            children={LL.commonButtons.cancel()}
            type='secondary'
            disabled={loading}
            onClick={onClose}
          />
          <Button
            children={LL.newPaymentModal.createButton[mobile ? 'mobile' : 'fullText']()}
            suffix={loading ? 'loading' : 'arrow_right'}
            disabled={loading}
            onClick={onSubmit}
          />
        </div>
      }
    >
      <Form disabled={loading} formHook={form} className={styles.form}>
        <div className={styles.amountRow}>
          <CurrencyInput name='amount' title={LL.newPaymentModal.amountInput.title()} />
          <Input name='title' title={LL.newPaymentModal.TitleInput.title()} />
        </div>
        <TextArea name='description' title={LL.newPaymentModal.descriptiontInput.title()} />
        <Divider
          className={showAdvanced ? '' : styles.hidden}
          text={LL.newPaymentModal.payerDivider()}
          size='tiny'
        />
        <div className={showAdvanced ? styles.payer : styles.hidden}>
          <Input
            name='payerEmail'
            tooltip='to do'
            title={LL.commonFields.email.title()}
            wrapperClassName={styles.email}
          />
          <Input
            name='payerName'
            tooltip='to do'
            wrapperClassName={styles.name}
            title={LL.commonFields.fullName.title()}
          />
          <Input
            name='payerId'
            tooltip='to do'
            title={LL.newPaymentModal.idInput.title()}
            wrapperClassName={styles.id}
          />
        </div>
        <Divider
          className={showAdvanced ? '' : styles.hidden}
          size='tiny'
          text={LL.newPaymentModal.documentDivider()}
        />
        <div className={showAdvanced ? styles.document : styles.hidden}>
          <Input
            name='documentName'
            title={LL.commonFields.documentTitle.title()}
            tooltip='to do'
            wrapperClassName={styles.title}
          />
          <DocumentTypeSelector
            name='documentType'
            title={LL.commonFields.documentType.title()}
            className={showAdvanced ? '' : styles.hidden}
          />
          <Input
            name='documentContentType'
            title={LL.newPaymentModal.contentTypeField.title()}
            wrapperClassName={showAdvanced ? '' : styles.hidden}
          />
        </div>
        <Divider className={showAdvanced ? '' : styles.hidden} size='tiny' text='Other' />
        <div className={showAdvanced ? styles.other : styles.hidden}>
          <DatePicker
            name='reqdExctnDt'
            icon='schedule_outline'
            placeholder={LL.newPaymentModal.executionDateInput.placeholder()}
            title={LL.newPaymentModal.executionDateInput.title()}
            disabled={loading}
            disabledDate={(date) => currentMoment.isSameOrAfter(date)}
            tooltip={LL.newPaymentModal.executionDateInput.tooltip()}
            isRtl
          />
          <DatePicker
            name='xpryDt'
            icon='schedule_outline'
            placeholder={LL.newPaymentModal.dueDateInput.placeholder()}
            title={LL.newPaymentModal.dueDateInput.title()}
            disabled={loading}
            disabledDate={(date) => currentMoment.isSameOrAfter(date)}
            tooltip={LL.newPaymentModal.dueDateInput.tooltip()}
            isRtl
          />
        </div>
        <PaymentUpload
          convertPdfToXml={convertPdfToXml}
          disabled={loading}
          setPrmDocument={doc => setPrmDocument(doc)}
        />
        <Checkbox
          className={showAdvanced ? '' : styles.hidden}
          children={LL.newPaymentModal.skipGenerate()}
          value={skipGenerateActions}
          onChange={() => setSkipGenerateActions(!skipGenerateActions)}
        />
      </Form>
    </Modal>
  )
}
