import React, { useState } from "react";
import { message, RcFile, Upload } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import xmlApi from "apis/xml";
import aiApi from "apis/aiApi";
import { PrmDocument } from "apis/xml/types";
import { file2Base64, file2Blob } from "utils";
import moment from "moment";
import { useSelector } from "react-redux";
import { ApplicationState } from "modules";
import { encode } from 'js-base64';
import styles from './PaymentUpload.module.scss'


interface Props {
  convertPdfToXml: boolean
  setPrmDocument: (document: PrmDocument) => void
  disabled: boolean
  formHook?: any
}


export default function PaymentUpload({ formHook, disabled, convertPdfToXml, setPrmDocument }: Props) {
  const userSub = useSelector((state: ApplicationState) => state.authentication.user?.sub);
  const parentSub = useSelector((state: ApplicationState) => state.authentication.user?.parentSub);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const { LL } = useI18nContext();
  const [uploadText, setUploadText] = useState<string>(LL.commonFields.upload.prompt());

  const processUploadedFile = async (file: RcFile) => {
    if (error) setError(undefined);

    const base64 = await file2Base64(file)
    const content = base64.replace(/^data:.*\/\w+;base64,/, '');

    const prmDocument: PrmDocument = {
      name: file.name,
      content,
      contentType: file.type,
      type: 'dummy' // * doesn't matter is not taken from here
    }

    let timeouts: NodeJS.Timeout[] = []
    
    try {
      setLoading(true);
      if (file.type.includes('/xml') || file.type.includes('/EIP')) {
        setUploadText('Converting your XML into PRM...');
        const { data: prm } = await xmlApi.transformXmlIntoPrm(file2Blob(file), parentSub || userSub);

        formHook.reset(
          {
            title: prm.orderId,
            description: prm.description,
            reqdExctnDt: prm.reqdExctnDt ? moment(prm.reqdExctnDt) : undefined,
            xpryDt: prm.xpryDt ? moment(prm.xpryDt) : undefined,
            amount: prm.amount,
            // payer
            payerEmail: prm.payer?.email,
            payerName: prm.payer?.name,
            payerId: prm.payer?.id,
            // document
            documentName: file.name,
            documentContentType: prm.document.contentType || file.type,
            documentType: prm.document.type,
          },
          {
            keepDirtyValues: false
          }
        )
      } else if (file.type.includes('/pdf') && convertPdfToXml) {
        setUploadText('Converting your PDF into XML invoice...');

        const userMessages = [
          'Still converting...',
          'Hold tight, we are working VERY hard...',
          'Takes a bit more than expected...',
          'Where did you get such a complex PDF?',
          'Almost done, we promise...',
          'Eh, just wait a bit :)'
        ];

        timeouts = userMessages.map((msg, i) => setTimeout(() => setUploadText(msg), 5000 * (i + 1)))

        const response = await aiApi.pdfToXml(file2Blob(file));

        timeouts.forEach(timeout => clearTimeout(timeout));

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, "application/xml");
        const root = xmlDoc.documentElement; // Assuming you want to add to the root

        const pdfContentXmlNode = xmlDoc.createElement("OriginalPdfContent");
        const pdfNameXmlNode = xmlDoc.createElement("OriginalPdfName");
        pdfContentXmlNode.textContent = prmDocument.content;
        pdfNameXmlNode.textContent = prmDocument.name;
        root.appendChild(pdfNameXmlNode);
        root.appendChild(pdfContentXmlNode);

        const serializer = new XMLSerializer();
        const xmlWithPdfStr = serializer.serializeToString(xmlDoc);

        prmDocument.content = encode(xmlWithPdfStr);
        prmDocument.contentType = 'application/xml'
        prmDocument.name += '.xml'
      }
    } catch (err: any) { 
      timeouts.forEach(timeout => clearTimeout(timeout));
      message.error(`${err?.message}. Error, check the console`)
      console.log('Some error, check the console')
      console.log(err)
      setUploadText(LL.commonFields.upload.prompt())
    } finally {
      setLoading(false)
    }
    
    setPrmDocument(prmDocument)
    setUploadText(prmDocument.name)
  }


  return (
    <Upload
      className={styles.upload}
      disabled={disabled || loading}
      icon={loading ? 'loading' : 'document'}
      name='file'
      beforeUpload={processUploadedFile}
      size='small'
      errorMessage={error}
      title={LL.paymentCommonFields.upload.title()}
      text={uploadText}
      helpText={LL.commonFields.upload.helpText()}
    />
  )
}
