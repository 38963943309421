import { message } from '@sis-lab/web-ui-components';
import axios from 'axios';
import { useState } from 'react';
import { getDocumentCertificateService } from 'services/documents';
import { downloadBlobFile } from 'utils';

const useDownloadCertificate = (fingerprint: string, signed: boolean): [() => void, boolean] => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadCertificate = async () => {
    setIsLoading(true);
    try {
      const [fileData, fileName] = await getDocumentCertificateService(fingerprint, signed);
      if (fileData && fileName) downloadBlobFile(fileData, fileName);
      else message.error('Some error occurred during download');
    } catch (err) {
      if (!axios.isAxiosError(err)) return;
      message.error(`This document can/'t be downloaded now`);
    }
    setIsLoading(false);
  };

  return [downloadCertificate, isLoading]
}

export default useDownloadCertificate;
