import { instance } from './instance';
import { AiPreferences } from './types';

export const getPreferences = () => (
  instance.get<AiPreferences>(`/account/preferences`)
)

export const classifyByFileName = (fileName: string) => (
  instance.post<string>('/documents/type', { text: fileName })
)

export const getSummary = (file: any, fileName: string) => {
  const formData = new FormData();
  formData.append('upload', file, fileName);
  return instance.post<string>('/documents/summary', formData)
}

export const pdfToXml = (file: any) => {
  const formData = new FormData();
  formData.append('invoice', file);
  return instance.post<string>('/invoices/pdf-to-xml', formData)
}