import { getDocumentCertificate, getSignedDocumentCertificate } from 'apis/contract';

export const getDocumentCertificateService = async (fingerprint: string, signed?: boolean): Promise<[Blob, string]> => {
  const documentCertificateResponse = signed
    ? await getSignedDocumentCertificate(fingerprint)
    : await getDocumentCertificate(fingerprint);

  const filetype = 'pdf';
  const filename = fingerprint;
  const blob = new Blob(
    [documentCertificateResponse.data],
    { type: documentCertificateResponse.headers['content-type'] },
  );
  return [blob, filetype ? `${filename}.${filetype}` : ''];
};
