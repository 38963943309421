import React, { useContext } from 'react';
import { Button, Card, CardHeader, Form, Input, Skeleton, useForm } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import DocumentContext from '../DocumentContext/DocumentContext';
import useDownloadCertificate from './useDownloadCertificate';
import styles from './DocumentValidityCard.module.scss';

interface Props {
  fingerprint: string
  isValidator?: boolean
  isHidden?: boolean
}

export default function DocumentValidityCard({ fingerprint, isValidator, isHidden }: Props) {
  const [downloadCert, certIsLoading] = useDownloadCertificate(fingerprint, !isValidator);
  const { document } = useContext(DocumentContext)
  const { LL } = useI18nContext()
  const form = useForm();

  return (
    <Card className={[styles.card, isHidden && styles.hidden].join(' ')} size='small'>
      <CardHeader
        title={LL.documentValidityCard.title()}
        suffix={
          <Button
            children={LL.documentValidityCard.downloadButton()}
            suffix={certIsLoading ? 'loading' : 'download'}
            type='secondary'
            mobileIcon={certIsLoading ? 'loading' : 'download'}
            className={isValidator || !document ? styles.hidden : ''}
            disabled={certIsLoading}
            onClick={downloadCert}
          /> 
        }
      />
      {!document && <Skeleton count={4} style={{ marginBottom: 10 }} />}
      <Form disabled className={!document ? styles.hidden : ''} formHook={form} size='medium'>
        <div className={isValidator ? styles.hidden : styles.explanation}>
          <b>{LL.documentValidityCard.certExplanationStart()} </b>
          {LL.documentValidityCard.certExplanation()}
        </div>
        <Input
          name='documentID'
          title={LL.documentValidityCard.documentId.title()}
          tooltip={LL.documentValidityCard.documentId.tooltip()}
          value={document?.id}
        />
        <Input
          name='fingerprint'
          title={LL.commonFields.fingerprint.title()}
          tooltip={LL.commonFields.fingerprint.tooltip()}
          value={document?.fingerprint}
        />
      <Input
          name='certificateID'
          title={LL.documentValidityCard.ownerCertFingerprint.title()}
          tooltip={LL.documentValidityCard.ownerCertFingerprint.tooltip()}
          value={document?.creator.certificateId}
        /> 
        <Input
          name='url'
          title={LL.documentValidityCard.storageUrl.title()}
          wrapperClassName={document?.uri ? '' : styles.hidden}
          value={document?.uri || '—'}
        />
      </Form>
    </Card>
  )
}
